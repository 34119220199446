import React from 'react'
import Page from '../components/Page'

const IndustriesPage = () => (
  <Page title="INDUSTRIES">
    <p className="mt-8 w-2/3 text-center">
    Life Sciense, Physics, High-Tech Software, Electronics, Media, Energy, Telecom, AI, Data Security, VR, Mechanics, Automotive, Aerospace and Defense, Industrial Manufacturing
    </p>
  </Page>
)

export default IndustriesPage